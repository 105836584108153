import React, { useEffect, useState } from 'react'
import styles from './About.module.scss'
import { Stepper } from 'shared/ui/Stepper/Stepper'
import { PlayerInfoForm } from 'features/ui/PlayerInfoForm/PlayerInfoForm'
import ActionButtons from '../../commonComponents/ActionButtons/ActionButtons'
import { Gender } from "widgets/ui/GenderSelect/GenderSelect.typings";
import { gaitVariants, monthArr, nationalities, weightRange, heightRange } from '../../../../../features/ui/PlayerInfoForm/PlayerInfoForm.config';
import { AboutSettings, LifeGoals, CharacterTraits } from './About.typings'
import { countriesArr, GoalsType, TraitsSlot, VisibleComponent } from './About.config'
import { getRandomFullName } from './utils/getRandomFullName'
import { createRange } from './utils/createRange'
import { getDayOfBirth } from './utils/getDayOfBirth'
import { getRandom } from './utils/getRandom'
import CharacterTraitsSelect from './components/CharacterTraitsSelect/CharacterTraitsSelect'
import LifeGoalSelect from './components/LifeGoalSelect/LifeGoalSelect'
import { Arr } from './components/CharacterTraitsSelect/CharacterTraitsSelect.typings'

const About = () => {

    const [isPlayerInfoFormVisible, setPlayerInfoFormVisible] = useState<boolean>(true)
    const [isLifeGoalVisible, setLifeGoalVisible] = useState<boolean>(false)
    const [isCharacterTraitsVisible, setCharacterTraitsVisible] = useState<boolean>(false)
    const [aboutSettings, setAboutSettings] = useState<AboutSettings>(
        {
            firstName: '',
            lastName: '',
            country: '',
            city: '',
            gender: Gender.male,
            weight: 0,
            height: 0,
            gait: gaitVariants[0],
            age: '',
            monthOfBirth: '',
            dayOfBirth: '',
            nationality: ''
        }
    )
    const [lifeGoals, setLifeGoals] = useState<LifeGoals>(
        {
            mainGoalId:null,
            additionalGoalId:null
        }
    )
    const [selectedTraitSlot, setSelectedTraitSlot] = useState<TraitsSlot>(TraitsSlot.trait1)
    const [characterTraits, setCharacterTraits] = useState<CharacterTraits>(
        {
            trait1:null,
            trait2:null,
            trait3:null
        }
    )

    useEffect(() => {
        console.log(TraitsSlot[selectedTraitSlot])
    }, [selectedTraitSlot])

    const handleChangeLifeGoals = (type:GoalsType, id:number) => {
        if(type === GoalsType.mainGoal){
            const newState = {...lifeGoals, mainGoalId:id}
            setLifeGoals(newState)
        } else if(type === GoalsType.additionalGoal){
            const newState = {...lifeGoals, additionalGoalId:id}
            setLifeGoals(newState)
        }
    }

    const handleChangeCharacterTraits = (trait:Arr) => {
        const newState = {...characterTraits, [TraitsSlot[selectedTraitSlot]]:trait}
        setCharacterTraits(newState)
    }

    const handleChangeAboutSettings = (key: string, value: any) => {
        const newSettings = {
            ...aboutSettings,
            [key]: value
        }
        setAboutSettings(newSettings)
    }

    const handleRandomName = (firstName: string, lastName: string) => {
        const newSettings = {
            ...aboutSettings,
            firstName,
            lastName
        }

        setAboutSettings(newSettings)
    }

    const getRandomAboutSettings = () => {
        const prevState = { ...aboutSettings }

        prevState.gender = Object.keys(Gender)[Math.floor(Math.random() * Object.keys(Gender).length)] as Gender
        prevState.nationality = prevState.gender === Gender.male ?
            nationalities[Math.floor(Math.random() * nationalities.length)].titles.male :
            nationalities[Math.floor(Math.random() * nationalities.length)].titles.female
        prevState.country = countriesArr[Math.floor(Math.random() * countriesArr.length)].name
        prevState.city = getRandom(countriesArr[countriesArr.findIndex(item => item.name === prevState.country)].cities)
        prevState.weight = Number(getRandom(createRange(weightRange, 1)))
        prevState.height = Number(getRandom(createRange(heightRange, 0.1)))
        prevState.gait = gaitVariants[Math.floor(Math.random() * gaitVariants.length)]
        prevState.age = getRandom(Array.from(createRange([18, 60], 1)))
        prevState.monthOfBirth = getRandom(monthArr)
        prevState.dayOfBirth = getDayOfBirth(prevState.monthOfBirth)
        prevState.firstName = getRandomFullName(prevState.gender, prevState.nationality).firstName
        prevState.lastName = getRandomFullName(prevState.gender, prevState.nationality).lastName

        setAboutSettings(prevState)
    }

    const switchComponents = (visibleComponent: VisibleComponent) => {
        switch(visibleComponent){
            case(VisibleComponent.playerInfoForm):{
                setPlayerInfoFormVisible(true)
                setLifeGoalVisible(false)
                setCharacterTraitsVisible(false)
                break
            }
            case(VisibleComponent.goalSelect):{
                setPlayerInfoFormVisible(false)
                setLifeGoalVisible(true)
                setCharacterTraitsVisible(false)
                break
            }
            case(VisibleComponent.traitSelect):{
                setPlayerInfoFormVisible(false)
                setLifeGoalVisible(false)
                setCharacterTraitsVisible(true)
                break
            }
        }
    }

    const handleTraitSelect = (traitSlot:TraitsSlot) => {
        switchComponents(VisibleComponent.traitSelect)
        setSelectedTraitSlot(traitSlot)
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.stepper}>
                <Stepper steps={3} currentStep={1} />
            </div>
            <div className={styles.playerInfoForm}>
                {
                    isPlayerInfoFormVisible &&
                    <PlayerInfoForm
                        aboutSettings={aboutSettings}
                        onChange={handleChangeAboutSettings}
                        randomName={handleRandomName}
                        gender={aboutSettings.gender}
                        nationality={aboutSettings.nationality}
                        onSelect={switchComponents}
                        onTraitSelect={handleTraitSelect}
                        lifeGoals={lifeGoals}
                        characterTraits={characterTraits}
                    />
                }
            </div>
            <div className={styles.actionButtonsBlock}>
                <ActionButtons
                    onRandom={getRandomAboutSettings}
                    onDecline={switchComponents}
                />
            </div>
            {
                isCharacterTraitsVisible &&
                <CharacterTraitsSelect
                    onMainGoalSelect={switchComponents}
                    characterTraits={characterTraits}
                    onTraitSelect={handleTraitSelect}
                    onTraitSelectItem={handleChangeCharacterTraits}
                    lifeGoals={lifeGoals}
                />
            }
            {
                isLifeGoalVisible &&
                <LifeGoalSelect
                    onMainGoalSelect={switchComponents}
                    lifeGoals={lifeGoals}
                    onSelect={handleChangeLifeGoals}
                    onTraitSelectItem={handleChangeCharacterTraits}
                    characterTraits={characterTraits}
                    onTraitSelect={handleTraitSelect}
                />
            }
        </div>
    )
}

export default About