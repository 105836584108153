import type { SendProps, PersonProps, CommandProps } from './types'
import { rpc } from 'altv-xrpc-webview'
import { baseApi } from 'app/api/baseApi'

export const chatApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        print: builder.mutation<{ status: boolean }, { status: boolean }>({
            async queryFn(data) {
                try {
                    const res = await rpc.emitServer('chat:print', data)
                    console.log('res', res)
                    return { data: res }
                } catch (error) {
                    console.log('err', error)
                    return { error: true }
                }
            },
        }),
        send: builder.mutation<SendProps, SendProps>({
            async queryFn(data) {
                try {
                    const res = await rpc.emitServerWithTimeout(
                        'chat:send',
                        2000,
                        data
                    )
                    return { data: res }
                } catch (error) {
                    return { error: error }
                }
            },
        }),
        command: builder.mutation<CommandProps, CommandProps>({
            async queryFn(data) {
                try {
                    const res = await rpc.emitServerWithTimeout(
                        'chat:command',
                        2000,
                        data
                    )
                    return { data: res }
                } catch (error) {
                    return { error: error }
                }
            },
        }),
        messages: builder.query<any, void>({
            async queryFn() {
                return { data: [] }
            },
            async onCacheEntryAdded(
                _,
                { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
            ) {
                try {
                    await cacheDataLoaded

                    const listener = (data: any) => {
                        updateCachedData((draft = []) => {
                            return [...draft, data].slice(-50)
                        })
                    }
                    rpc.onServer('chat:message', listener)
                } catch {
                    // ...
                }
                await cacheEntryRemoved
            },
        }),
        chatStatus: builder.query<boolean, void>({
            queryFn: async () => ({ data: false }),
            async onCacheEntryAdded(
                _,
                { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
            ) {
                try {
                    await cacheDataLoaded

                    rpc.onClient('chat:focus', () => {
                        updateCachedData(() => true)
                    })
                    rpc.onClient('hud:unfocus', () => {
                      updateCachedData(() => false)
                  })
                } catch {
                    // ...
                }
                await cacheEntryRemoved
            },
        }),
        personFind: builder.mutation<PersonProps, string>({
            async queryFn(data) {
                try {
                    // Отправляем данные через rpc и ожидаем ответ
                    const response = await rpc.emitServer(
                        'chat:personFind',
                        data
                    )
                    return { data: response }
                } catch (error) {
                    return { error }
                }
            },
        }),
    }),
})

export const {
    usePrintMutation,
    useSendMutation,
    useCommandMutation,
    useMessagesQuery,
    usePersonFindMutation,
    useChatStatusQuery
} = chatApi
