import { rpc } from 'altv-xrpc-webview'
import { baseApi } from 'app/api/baseApi'
import { OpenProps, CommandProps } from './types'

export const interactionWheelApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        open: builder.query<OpenProps, void>({
            async queryFn() {
                return { data: { isOpen: false, type: 'withPlayer' } }
            },
            async onCacheEntryAdded(
                _,
                { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
            ) {
                try {
                    await cacheDataLoaded

                    const listener = (...args: unknown[]) => {
                        const [data] = args as [OpenProps]
                        updateCachedData((draft) => {
                            if (draft) {
                                draft.isOpen = data.isOpen
                                draft.type = data.type
                            }
                        })
                    }
                    rpc.onClient('interaction:open', listener)
                } catch {
                    // ...
                }
                await cacheEntryRemoved
            },
        }),
        command: builder.mutation<CommandProps, CommandProps>({
            async queryFn(data) {
                try {
                    const res = await rpc.emitServer(
                        'interaction:command',
                        data
                    )
                    console.log('res', res)
                    return { data: res }
                } catch (error) {
                    console.log('err', error)
                    return { error: true }
                }
            },
        }),
    }),
})

export const { useOpenQuery, useCommandMutation } = interactionWheelApi
