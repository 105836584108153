import React, { /*SVGProps, */useEffect, useState } from 'react'
import styles from './InteractionWheel.module.scss'
import { ItemsWheel } from 'entities/ui/InteractionWheel/components/ItemsWheel/ItemsWheel'
import classNames from 'classnames'
import { characterInteractions } from 'entities/ui/InteractionWheel/InteractionWheel.config'
import { useOpenQuery, useCommandMutation } from './api/interactionWheelApi';
import {initialInteractionsParams} from "entities/ui/InteractionWheel/InteractionWheel.config";
import { InteractionsParams, /*Props,*/ HoverItem } from './InteractionWheel.typings'


export const InteractionWheel = () => {

    const {data: interactionShow = {isOpen:false, type:'withPlayer'}} = useOpenQuery()
    const show = interactionShow.isOpen
    const type = interactionShow.type ?? null
    const [params/*, setParams*/] = useState<InteractionsParams>(initialInteractionsParams)

    const items = characterInteractions[type]?.(params) || []
    const count = items.length
    const [hoverItem, setHoverItem] = useState<HoverItem | null>(null)
    const [hoverSubItem, setHoverSubItem] = useState<HoverItem | null>(null)

    const handleMouseOver = (index: number) => {
        setHoverItem({
            ...items[index],
            index,
        })
    }

    const [data] = useCommandMutation()

    const handleMouseOverSubItem = (index: number) => {
        const item = hoverItem?.items?.[index]

        if (item) {
            setHoverSubItem({
                ...item,
                index,
            })
        }
    }

    const outerSectors = 16
    const outerAngle = 360 / 16
    const innerAngle = 360 / count
    const rotateOuterDeg =
        hoverItem?.index && hoverItem.items
            ? (hoverItem.index + 0.5) * innerAngle -
              (outerAngle * hoverItem.items?.length) / 2
            : 0

    useEffect(() => {
        setHoverSubItem(null)
    }, [hoverItem])

    return show ?(
        <div className={classNames(styles.wrapperOuter, styles.bigCircle)}>
            {hoverItem?.items && (
                <ItemsWheel
                    items={hoverItem?.items}
                    hoverItem={hoverSubItem}
                    onClick={()=> data({type})}
                    onMouseOver={handleMouseOverSubItem}
                    sectorWidth={68}
                    length={outerSectors}
                    rotate={rotateOuterDeg}
                    containerSize={800}
                    iconSize={42}
                />
            )}
            <div className={styles.wrapperInner}>
                <ItemsWheel
                    items={items}
                    hoverItem={hoverItem}
                    onClick={() => data({type})}
                    onMouseOver={handleMouseOver}
                    hasCircle
                    containerSize={565}
                />
                <div className={styles.title}>
                    {hoverSubItem?.title || hoverItem?.title}
                </div>
            </div>
        </div>
    ): null
}
