import { ProgressSvg } from 'shared/ui/ProgressSvg/ProgressSvg'
import { Mood } from 'shared/icons/Mood'
import { Toilet } from 'shared/icons/Toilet'
import { Shower } from 'shared/icons/Shower'
import { Guard } from 'shared/icons/Guard'
import { Sleep } from 'shared/icons/Sleep'
import { Pizza } from 'shared/icons/Pizza'
import { Cup } from 'shared/icons/Cup'
import styles from './HudNeeds.module.scss'
import React, {useEffect, useState} from 'react'
import rpc from 'altv-rpc'
import cn from 'classnames'

export const HudNeeds = () => {

    const [values, setValues] = useState({
        show: false,
        params: {
          toilet: 1,
          shower: 1,
          immunity: 1,
          sleep: 1,
          mood: 1,
          food: 1,
          water: 1
        }
    })

    const callback = ({ data }: any) => {
        const newValues = {...values, ...data}
        setValues(newValues)
    }

    const event = "hud:needs"

    useEffect(() => {
        rpc.on(event, callback)

        return () => {
            rpc.off(event, callback)
        }
    },[])

    return (
        <div className={styles.container}>
            <ProgressSvg
                className={cn(styles.icon, values.params.toilet > 0.3 && !values.show && styles.iconHide)}
                Icon={Toilet}
                percent={values.params.toilet}
            />
            <ProgressSvg
                className={cn(styles.icon, values.params.shower > 0.3 && !values.show && styles.iconHide)}
                Icon={Shower}
                percent={values.params.shower}
            />
            <ProgressSvg
                className={cn(styles.icon, values.params.immunity > 0.3 && !values.show && styles.iconHide)}
                Icon={Guard}
                percent={values.params.immunity}
            />
            <ProgressSvg
                className={cn(styles.icon, values.params.sleep > 0.3 && !values.show && styles.iconHide)}
                Icon={Sleep}
                percent={values.params.sleep}
            />
            <ProgressSvg
                className={cn(styles.icon, values.params.mood > 0.3 && !values.show && styles.iconHide)}
                Icon={Mood}
                percent={values.params.mood}
            />
            <ProgressSvg
                className={cn(styles.icon, values.params.food > 0.3 && !values.show && styles.iconHide)}
                Icon={Pizza}
                percent={values.params.food}
            />
            <ProgressSvg
                className={cn(styles.icon, values.params.water > 0.3 && !values.show && styles.iconHide)}
                Icon={Cup}
                percent={values.params.water}
            />
        </div>
    )
}
