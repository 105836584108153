import { rpc } from 'altv-xrpc-webview'
import { baseApi } from 'app/api/baseApi'

export const voiceChatApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        change: builder.query<boolean, void >({
            queryFn: async () => ({data:false}),
            async onCacheEntryAdded(
                _,
                {updateCachedData, cacheDataLoaded, cacheEntryRemoved}
            ) {
                try {
                    await cacheDataLoaded
                    rpc.onClient('voice:change', (value:any) => {
                        updateCachedData(() => value.isActive)
                    })
                } catch (error) {
                    //...
                }
                await cacheEntryRemoved
            },
        }),
        set: builder.query<string, void>({
            queryFn: async () => ({data:'talk'}),
            async onCacheEntryAdded(
                _,
                {updateCachedData, cacheDataLoaded, cacheEntryRemoved}
            ) {
                try {
                    await cacheDataLoaded
                    rpc.onServer('voice:set', (value:any) => {
                        updateCachedData(() => value.type)
                    })
                } catch (error) {
                    //...
                }
                await cacheEntryRemoved
            },
        }),
        
    }),
})

export const {
    useChangeQuery,
    useSetQuery,
} = voiceChatApi
