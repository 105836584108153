import React, { useEffect, useRef } from 'react'
import stylesBase from './Chat.module.scss'
import { useState } from 'react'
import Message from './components/Message'
import { usePrintMutation, useSendMutation, useCommandMutation, useMessagesQuery, usePersonFindMutation, useChatStatusQuery } from './api/chatApi'
import cn from 'classnames'
import { useSelector } from 'react-redux'
import { CHAT_TAGS } from './Chat.config'
import { isEmptyString } from './utils/isEmptyString';
import { getFormatGender } from './utils/getFormatGender'
import { friendsSelector } from 'app/redux-toolkit/friendsSlice'
import { MessagesProps } from './api/types'

export const Chat = () => {
    const { data: chatStatus = false } = useChatStatusQuery()
    const friends = useSelector(friendsSelector);
    const [activeTag, setActiveTag] = useState<number>(1)
    const [send] = useSendMutation();
    const [command] = useCommandMutation()
    const [value, setValue] = useState('')
    const [personFind] = usePersonFindMutation();
    const inputRef = useRef<HTMLTextAreaElement>(null)

    const errorTimerRef = useRef<NodeJS.Timeout | null>(null)

    const handleCommand = (value: string) => {
        const commandEndIndex = value.split('').findIndex(item => item === ' ')
        const enteredCommand = value.slice(1, commandEndIndex)
        const enteredArgs = value.slice(commandEndIndex + 1).split(' ')
        command({
            name: enteredCommand,
            args: enteredArgs,
        })
        setValue('')
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement> | React.KeyboardEvent) => {
        event.preventDefault()
        if (value[0] === '#' && value.length === 7) {
            const isFriend = friends.find((item: any) => item.staticId === Number(value.slice(1)))
            if (isFriend) {
                setValue(`${isFriend.name} ${isFriend.staticId}`)
            } else {
                const onPersonFind = async () => {
                    try {
                        const result = await personFind(value); // Вызываем мутацию
                        if (result.data) {
                            setValue(`${getFormatGender(result.data.gender)} ${result.data.uuid}`)
                        }
                    } catch (e) {
                        console.error('Error', e);
                    }
                };
                onPersonFind()
            }
        } else if (activeTag === 5) {
            const isToDoCorrect = checkToDoMessage(value)
            if (isToDoCorrect) {
                send({ text: value, type: 'toDo' })
            } else if (!errorTimerRef.current) {
                typeErrorToDo()
                errorTimerRef.current = setTimeout(() => { errorTimerRef.current = null }, 60000)
            }
            setValue('')
        } else if (value[0] === '/') {
            handleCommand(value)
        } else {
            if (!isEmptyString(value)) {
                if (value[0] !== '/' && value[0] !== '#') {
                    send({ text: value, type: CHAT_TAGS.find(item => item.id === activeTag)?.text || '' });
                    setValue('')
                } else {
                    setValue('')
                }
            } else {
                setValue('')
            }
        }
    }
    //const [isTagListActive, setTagListActive] = useState<boolean>(false)
    const [isTyping, setTyping] = useState<boolean>(false)
    const [isChatActive, setChatActive] = useState<boolean>(false)
    console.log(isChatActive)

    useEffect(() => {
        const callback = (e: any) => {
            if (e.keyCode === 9) {
                e.preventDefault()
                setActiveTag(prev =>
                    activeTag < CHAT_TAGS.length ? prev + 1 : 1
                )
            }
        }

        document.addEventListener('keydown', callback)

        return () => {
            document.removeEventListener('keydown', callback)
        }
    }, [activeTag])

    const checkToDoMessage = (value: string) => {
        const isStar = value.includes('*')
        if (isStar) {
            return value.split('').filter(item => item === '*').length === 1
        }
    }

    const typeErrorToDo = () => {
        const fixedDate = new Date()
        const errorMessage = {
            text:
                `Используйте для описания слов и действий персонажа: в формате: слово*действие [действие должно отвечать на вопрос "что сделав?"].

Например:
Ура, получилось!*радостно вскинув руки`,
            type: 'error',
            staticId: '000000',
            name: null,
            gender: 'male',
            sendedAt: fixedDate,
        }
        setErrorMessages((prev) => prev ? [...prev, errorMessage] : [errorMessage]);
        setValue('')
    }

    const timerRef = useRef<ReturnType<typeof setTimeout>>()

    const onTyping = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const textarea = e.target;
        const lineHeight = 18;
        textarea.style.height = '18px';
        const hasFilledFirstLine = textarea.scrollHeight > lineHeight;
        const newHeight = hasFilledFirstLine ? Math.min(textarea.scrollHeight, 35) : lineHeight;
        textarea.style.height = `${newHeight}px`;

        setValue(e.target.value)
        clearTimeout(timerRef.current)
        clearTimeout(activeChatTimerRef.current)
        setTyping(true)
        setChatActive(true)
        timerRef.current = setTimeout(() => setTyping(false), 2000)
        activeChatTimerRef.current = setTimeout(() => setChatActive(false), 180000)
    }

    const [print/*, { data, error, isLoading }*/] = usePrintMutation();

    const activeChatTimerRef = useRef<ReturnType<typeof setTimeout>>()

    useEffect(() => {
        if (isTyping !== undefined) {
            print({ status: isTyping });
        }
    }, [isTyping, print]);


    useEffect(() => {
        setChatActive(chatStatus)
        if (chatStatus) {
            inputRef.current?.focus()
        }
        else {
            const activeElement = document.activeElement as HTMLElement;
            if (activeElement?.blur) {
                activeElement.blur();
            }
        }

    }, [chatStatus])

    useEffect(() => {
        if (value[0] === '/' && value.length > 1) {
            const commandEndIndex = value.split('').findIndex(item => item === ' ')
            if (commandEndIndex !== -1) {
                const foundTag = CHAT_TAGS.find(item => item.text.toUpperCase() === value.toUpperCase().slice(1, -1))?.id
                if (foundTag) {
                    setActiveTag(foundTag)
                    setValue('')
                }
            }
        }
    }, [value])

    const { data: serverMessages = [] /*, error:messageError, isLoading:messageIsLoading*/ } = useMessagesQuery();

    const [errorMessages, setErrorMessages] = React.useState<MessagesProps[]>([])
    const messagesPool = [...serverMessages, ...errorMessages].sort((a, b) => new Date(a.sendedAt).getTime() - new Date(b.sendedAt).getTime())

    const activeTagText = CHAT_TAGS.find(item => item.id === activeTag)?.text ?? ''

    const lastMessageRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messagesPool.length])

    const getTagBackground = (value: string, selectedTag: boolean) => {
        const tag = value.toUpperCase()
        if (tag === 'ME') {
            return selectedTag ? stylesBase.tagMe : stylesBase.tagItemMe
        } else if (tag === 'DO') {
            return selectedTag ? stylesBase.tagDo : stylesBase.tagItemDo
        } else if (tag === 'B') {
            return selectedTag ? stylesBase.tagB : stylesBase.tagItemB
        } else if (tag === 'W') {
            return selectedTag ? stylesBase.tagW : stylesBase.tagItemW
        } else if (tag === 'S') {
            return selectedTag ? stylesBase.tagS : stylesBase.tagItemS
        } else {
            return null
        }
    }
    useEffect(() => {
        const ref = inputRef.current
        let blurTimeout: any
        if (ref) {
            const handleFocus = () => {
                clearTimeout(blurTimeout)
                setChatActive(true)
            }
            const handleBlur = () => {
                blurTimeout = setTimeout(() => setChatActive(false), 3000)
            }

            ref.addEventListener("focus", handleFocus);
            ref.addEventListener("blur", handleBlur);

            return () => {
                clearTimeout(blurTimeout)
                ref.removeEventListener("focus", handleFocus);
                ref.removeEventListener("blur", handleBlur);
            };
        }
    }, [])

    const handleFindTag = (value: string) => {
        const possibleTag = value.slice(0, -1)
        const findedTag = CHAT_TAGS.find(item => item.text === possibleTag.toUpperCase())
        if (findedTag) {
            setActiveTag(findedTag.id)
        }
    }

    useEffect(() => {
        if (value[0] === '/') {
            handleFindTag(value)
        }
    }, [value])

    // const defaultToDo = [
    //     {
    //         text:'Ура, получилось!*радостно вскинув руки',
    //         type:'b',
    //         staticId:'123456',
    //         name:null,
    //         gender:'male',
    //         sendedAt: new Date(),
    //     }
    // ]

    return (
        <div className={cn(stylesBase.chat)}>
            <div className={stylesBase.chatListOverflow}>
                <div className={stylesBase.chatList}>
                    {messagesPool?.map((item: any, index: number) => (
                        <Message {...item} key={item.text + index} />
                    ))}
                    <div ref={lastMessageRef}></div>
                </div>
            </div>
            <div className={cn(stylesBase.chatInput, !chatStatus && stylesBase.chatInputHide, /*!isChatActive && stylesBase.chatInputInactive*/)}> {/*//добавить ! in chatStatus*/}
                <form onSubmit={handleSubmit}>
                    <div className={stylesBase.chatInfo}>
                        <textarea
                            className={stylesBase.textarea}
                            value={value}
                            onChange={(e) => onTyping(e)}
                            placeholder='Введите сообщение...'
                            ref={inputRef}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault(); // Предотвращаем добавление новой строки
                                    handleSubmit(e as any); // Вызываем обработчик отправки
                                }
                            }}
                        />
                    </div>
                    <div className={stylesBase.buttonBlock}>
                        <div className={stylesBase.tab}>TAB</div>
                        <div className={cn(stylesBase.tag, getTagBackground(activeTagText, true))}
                            onClick={() => setActiveTag(prev => activeTag < CHAT_TAGS.length ? prev + 1 : 1)}
                        >
                            {activeTagText}
                            {/* {
                                isTagListActive &&
                                <div className={stylesBase.tagList}>
                                    {
                                        CHAT_TAGS.map(item => {
                                            return (
                                                <div
                                                    className={cn(stylesBase.tagItem, getTagBackground(item.text, false))}
                                                    key={item.id}
                                                    onClick={() => setActiveTag(item.id)}
                                                >
                                                    {item.text}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            } */}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
