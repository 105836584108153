import React, { useMemo } from 'react'
import { MessagesProps } from '../api/types'
import stylesBase from './Message.module.scss'
import cn from 'classnames'
import { getFormatName } from 'shared/utils/getFormatMessageName'
import { useSelector } from 'react-redux';
import { friendsSelector } from 'app/redux-toolkit/friendsSlice'

const Message: React.FC<MessagesProps> = (props: MessagesProps) => {
    const friends = useSelector(friendsSelector);
    const name = friends.find(({ staticId }: any) => staticId === props.staticId)?.name

    const toDoText = useMemo(() => {
        if (props.type.toUpperCase() === 'TODO') {
            const unformattedToDo = props.text.split('*')
            return {
                first: unformattedToDo[0] || '',
                second: unformattedToDo[1] || '',
            }
        }
        return { first: '', second: '' }
    }, [props.text, props.type])

    const getTextStyle = (type: string) => {
        switch (type) {
            case 'RP': {
                return stylesBase.rpText
                break
            }
            case 'ME': {
                return stylesBase.meText
                break
            }
            case 'DO': {
                return stylesBase.do
                break
            }
            case 'TODO': {
                return stylesBase.toDoText
                break
            }
            case 'B': {
                return stylesBase.b
                break
            }
            case 'S': {
                return stylesBase.s
                break
            }
            case 'W': {
                return stylesBase.w
                break
            }
            case 'ERROR':{
                return stylesBase.error
                break
            }
            default: break
        }
    }

    const getNameStyle = (type: string) => {
        switch (type) {
            case 'RP': {
                return stylesBase.rpName
                break
            }
            case 'ME': {
                return stylesBase.meName
                break
            }
            case 'DO': {
                return stylesBase.do
                break
            }
            case 'TODO': {
                return stylesBase.toDoName
                break
            }
            case 'B': {
                return stylesBase.b
                break
            }
            case 'S': {
                return stylesBase.s
                break
            }
            case 'W': {
                return stylesBase.w
                break
            }
            default:break
        }
    }

    const formatMessage = (text: string, type: string, staticId: string, gender: string) => {
        return (
            <div className={stylesBase.message}>
                <div>{props.sendedAt.getHours()}:{props.sendedAt.getMinutes()}</div>
                {
                    type !== 'DO' && type !== 'TODO' && type !== 'ERROR' &&
                    <span className={cn(getNameStyle(type.toUpperCase()))}>{getFormatName(name, gender)}&nbsp;</span>
                }
                {
                    type !== 'DO' && type !== 'TODO' && type !== 'ERROR' &&
                    <span className={cn(getNameStyle(type.toUpperCase()))}>#{staticId}&nbsp;</span>
                }
                {
                    type === 'ME' &&
                    <span className={cn(getNameStyle(type.toUpperCase()))}>:&nbsp;</span>
                }
                {
                    type === 'RP' &&
                    <span className={cn(getNameStyle(type.toUpperCase()))}>говорит:&nbsp;</span>
                }
                {
                    type === 'S' &&
                    <span className={cn(getNameStyle(type.toUpperCase()))}>кричит:&nbsp;</span>
                }
                {
                    type === 'W' &&
                    <span className={cn(getNameStyle(type.toUpperCase()))}>шепчет:&nbsp;</span>
                }
                {
                    type === 'TODO' &&
                    <>
                        <span className={stylesBase.toDoText}>{toDoText.first} -</span>
                    </>
                }
                <span className={cn(getTextStyle(type.toUpperCase()))}>
                    {type === 'B' && '(( '}
                    {type !== 'TODO' && `${text}`}
                    {type === 'B' && ' ))'}
                </span>
                {
                    type === 'TODO' &&
                    <span className={cn(getNameStyle(type.toUpperCase()))}>&nbsp; сказал &nbsp;{getFormatName(name, gender)}&nbsp;</span>
                }
                {
                    type === 'TODO' &&
                    <span className={cn(getNameStyle(type.toUpperCase()))}>#{staticId}, &nbsp;</span>
                }
                {
                    type === 'TODO' &&
                    <span className={cn(getNameStyle(type.toUpperCase()))}>{toDoText.second}</span>
                }
                {
                    type === 'DO' &&
                    <span className={cn(getNameStyle(type.toUpperCase()))}>&nbsp;{getFormatName(name, gender)}&nbsp;</span>
                }
                {
                    type === 'DO' &&
                    <>&nbsp;<span className={cn(getNameStyle(type.toUpperCase()))}>#{staticId}&nbsp;</span></>
                }
            </div>
        )
    }
    return <span className={stylesBase.message}>{formatMessage(props.text, props.type.toLocaleUpperCase(), props.staticId, props.gender)}</span>
}

export default Message
